//variables pour site Core
var retina = window.devicePixelRatio > 1;
var hasGMap = 0;


toastr.options = {
    'closeButton': true,
    'debug': false,
    'newestOnTop': false,
    'progressBar': false,
    'positionClass': 'toast-top-center',
    'preventDuplicates': false,
    'onclick': null,
    'showDuration': '300',
    'hideDuration': '1000',
    'timeOut': '5000',
    'extendedTimeOut': '1000',
    'showEasing': 'swing',
    'hideEasing': 'linear',
    'showMethod': 'fadeIn',
    'hideMethod': 'fadeOut'
};


//IE
if ($.browser.msie) {
	$('html').addClass('ie').addClass('ie'+parseInt($.browser.version,10));
}


//functions qu'on call pour site Core
var emploisuccess = function() {
	$('.js-emploiForm').fadeOut(500,function() {$('.emploisuccess').fadeIn();});
};

var emploierror = function() {
	$('.js-emploiForm .btn').fadeIn();
	$('.js-emploiForm').append('<p class="text-error">Une erreur est survenue, veuillez réessayer plus tard.</p>');
};


function toast(type, message, timeout) {
    timeout = timeout || 1000;
    toastr.options['timeOut'] = timeout;
    toastr[type](message)
}
function toast_them(toasts) {
    for (var type in toasts) {
        for (var key in toasts[type]) {
            toast(type, toasts[type][key], 5000);
        }
    }
}

function reenableButtons($form) {
    $form = $form || $(document);
    var $buttons = $form.find('button[type=submit]');
    $buttons.each(function() {
        var $button = $(this);
        $button.removeClass('disabled');
        if ($button.data('text')) {
            $button.text($button.data('text'));
        }
    });
}
function ajaxCall(url, callback, callbackArgs) {
    if (url.indexOf('?') < 0) {
        url += '?';
    }
    url += '&ajax';
    $.ajax({
        url: url,
        type: 'GET',
        async: true,
        cache: false,
        beforeSend: function () {
        },
        error: function (jqXHR, textStatus, errorThrown) {
            alert('Une erreur est survenue, veuillez réessayer plus tard.');
        },
        success: function(response) {
            if (response.toast) {
                toast_them(response.toast);
            }
            if (!response.error) {
                if (response.url) {
                    location.href = response.url;
                } else if (response.message == 'reload') {
                    location.href = location.href;
                } else {
                    if (callback) {
                        if (window[callback]) {
                            callback = window[callback];
                        }
                        var args = [response];
                        args = args.concat(callbackArgs);
                        callback.apply(null, args);
                    }
                }
            } else if (response.message) {
                toast('error', response.message);
            }
            if (response.new_url) {
                window.history.pushState(response, "", response.new_url);
            }
        }
    });
}
function sendFormAjax($form, method, callback, callbackArgs) {
    var formData = null;
    var processData = true;
    var contentType = null;
    method = method ? method : 'POST';
    if ($form.find('input[type=file]').length) {
        if (typeof IE9 !== 'undefined' && IE9) {
            // Envoyer des fichiers dans IE9 et moins �a marche pas en ajax
            if (!$form.parents('body').length) {
                $(document.body).append($form);
            }
            $form.unbind('submit');
            $form.unbind();
            $form[0].onSubmit = false;
            $form.submit();
            return false;
        } else {
            processData = false;
            contentType = false;
            formData = new FormData($form[0]);
        }
    } else {
        // Si on n'a pas de fichier � envoyer, on va juste faire un serialize bin normal
        formData = $form.serialize();
        contentType = 'application/x-www-form-urlencoded; charset=UTF-8';
    }
    var action = $form.attr('action');
    if (action.indexOf('?') < 0) {
        action += '?';
    }
    action += '&lan'+ getTheCurrentLang() +'&ajax';
    $.ajax({
        url: action,
        type: method,
        data: formData,
        dataType: 'json',
        processData: processData,
        contentType: contentType,
        async: true,
        cache: false,
        beforeSend: function () {
        },
        error: function (jqXHR, textStatus, errorThrown) {
            alert('Une erreur est survenue, veuillez réessayer plus tard.');
            reenableButtons($form);
        },
        success: function (response) {
            if (callback) {
                if (window[callback]) {
                    callback = window[callback];
                }
                var args = [response];
                args = args.concat(callbackArgs);
                callback.apply(null, args);
            } else {
                if (response.result) {
                    if (response.replaceHtml) {
                        $form.replaceWith($(response.replaceHtml));
                    }
                }
            }
        }
    });
}
function reDisplayForm($form) {
    if (typeof $form == 'string') { $form = $($form); }
    if ($form.is(':hidden')) {
        resetFormErrors($form);
        $form[0].reset();
        $form.parent().find('.Alert.has-success').addClass('hide');
        reenableButtons($form);
        $form.show();
    }
}
function resetFormErrors($form) {
    $form.find('.Alert.has-error').empty();
    $form.find('.Alert').addClass('hide');
    $form.find('.js-error').remove();
    $form.find('.FormElement.has-error').removeClass('has-error')
}
function displaySuccess($form) {
    if (typeof $form == 'string') { $form = $($form); }
    resetFormErrors($form);
    var $alert = $form.next('.Alert.has-success');
    if (!$alert.length) {
        $alert = $form.hide().parent().find('.Alert.has-success');
    }
    $form.hide();
    $alert.removeClass('hide');
    $('html, body').animate({
        scrollTop: $form.parent().offset().top - 100
    }, 600);
}
function displayErrors($form, messages) {
    if (typeof $form == 'string') { $form = $($form); }
    $form = $form || $(document);
    resetFormErrors($form);
    var focusIt = true;
    for (var name in messages) {
        if (name == 'global') {
            $form.find('.Alert.has-error').removeClass('hide').append(messages[name]);
            $('html, body').animate({
                scrollTop: $form.parent().offset().top
            }, 600);
        } else {
            var $ele = $form.find('*[name=' + name + ']');
            if ($ele.length) {
                if (focusIt && ($ele[0].offsetParent !== null)) {
                    $ele.focus();
                    focusIt = false;
                }
                var $formElement = $ele.closest('.FormElement');
                if ($formElement.length) {
                    $formElement.addClass('has-error');
                    $formElement.after('<label for="'+ $ele.attr('id') + '" class="js-error">' + messages[name] + '</label>')
                }
            }
        }
    }
}

function initSearchPagination() {
    $('#search_pagination').find('a').on('click touchstart', function(e) {
        var $this = $(this);
        loadSearchResults($this.attr('href'), {});
        e.preventDefault();
    });
}
function loadSearchResults(url, data) {
    if (url.indexOf('?') < 0) {
        url += '?';
    }
    url += '&ajax';
    $.ajax({
        url: url,
        type: 'POST',
        data: data,
        dataType: 'json',
        async: true,
        cache: false,
        beforeSend: function () {
        },
        error: function (jqXHR, textStatus, errorThrown) {
            alert('Une erreur est survenue, veuillez réessayer plus tard.');
        },
        success: function (response) {
            if (!response.error) {
                $('#search_results').html(response.html);
                $('#search_pagination').html(response.pagination);

                initSearchPagination();
                $('html,body').animate({
                    scrollTop: $('#search_results').offset().top
                }, 500);
            } else {
                if (response.message) {
                    displayErrors($('#search-form'), { 'global': response.message });
                } else if (response.messages) {
                    displayErrors($('#search-form'), response.messages);
                }
            }
            reenableButtons($('#search-form'));
        }
    });
}

//// Acolyte core -- TODO
function joss(msg) {
    if($("body").hasClass("isdev")) {
        console.log(msg);
    }
}


//gestion des events jQuery pour site Core
function initForms() {
	$('.js-form').on('submit', function(e){
        var $form = $(this);
        resetFormErrors($form);

        $form.find('input[name=special_email]').val('');
        sendFormAjax($form, 'POST', function (response) {
            if (response.toast) {
                toast_them(response.toast);
            }
            if (!response.error) {
                if (response.url) {
                    location.href = response.url;
                } else if (response.message == 'reload') {
                    location.href = location.href;
                } else {
                    displaySuccess($form);
                }
            } else {
                reenableButtons($form);
                if (response.message) {
                    displayErrors($form, { 'global': response.message });
                } else if (response.messages) {
                    displayErrors($form, response.messages);
                }
            }
        });
        e.preventDefault();
	});
}


/**
 * Module for displaying "Waiting for..." dialog using Bootstrap
 *
 * @author Eugene Maslovich <ehpc@em42.ru>
 * http://bootsnipp.com/snippets/featured/quotwaiting-forquot-modal-dialog
 * https://github.com/ehpc/bootstrap-waitingfor
 */
var waitingDialog = (function ($) {

    // Creating modal dialog's DOM
	var $dialog = $(
		'<div class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true" style="padding-top:15%; overflow-y:visible;">' +
		'<div class="modal-dialog modal-m">' +
		'<div class="modal-content">' +
			'<div class="modal-header"><h3 style="margin:0;"></h3></div>' +
			'<div class="modal-body">' +
				'<div class="progress progress-striped active" style="margin-bottom:0;"><div class="progress-bar" style="width: 100%"></div></div>' +
			'</div>' +
		'</div></div></div>');

	return {
		/**
		 * Opens our dialog
		 * @param message Custom message
		 * @param options Custom options:
		 * 				  options.dialogSize - bootstrap postfix for dialog size, e.g. "sm", "m";
		 * 				  options.progressType - bootstrap postfix for progress bar type, e.g. "success", "warning".
		 */
		show: function (message, options) {
			// Assigning defaults
			var settings = $.extend({
				dialogSize: 'm',
				progressType: ''
			}, options);
			if (typeof message === 'undefined') {
				message = 'Loading';
			}
			if (typeof options === 'undefined') {
				options = {};
			}
			// Configuring dialog
			$dialog.find('.modal-dialog').attr('class', 'modal-dialog').addClass('modal-' + settings.dialogSize);
			$dialog.find('.progress-bar').attr('class', 'progress-bar');
			if (settings.progressType) {
				$dialog.find('.progress-bar').addClass('progress-bar-' + settings.progressType);
			}
			$dialog.find('h3').text(message);
			// Opening dialog
			$dialog.modal();
		},
		/**
		 * Closes dialog
		 */
		hide: function () {
			$dialog.modal('hide');
		}
	};

})(jQuery);
