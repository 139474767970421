// **---------------------------------------------------**
// -------------------------------------------------------
// Global variables BEGIN

// store the window.scrollY value
var scrolled_y = 0;

// requestAnimationFrame
var ticking = false;

// ajax
var firstLoadDelay = 1250;

// Scrollbar
var Scrollbar;

// cache some stuff
var theBody = document.getElementsByTagName('body')[0];
var theContainer = document.getElementsByClassName('barba-container')[0];
var curUrl;
var CurLang;

// screen size
var screenWidth = window.innerWidth;
var screenHeight = window.innerHeight;

// Global variables END
// **---------------------------------------------------**
// -------------------------------------------------------

// Remember
function joss(msg) {
	console.log(msg);
}

// ** This function is used only when the site FIRST load to have a custom animation (only use when ajax) ** //
function initFirstPageLoad() {
	if (theBody.classList.contains('js-firstLoad')) {
		setTimeout(function(){ 
			theBody.classList.remove('js-firstLoad');
		}, firstLoadDelay);

		setTimeout(function() {
			ScrollItem.prototype.watch();
		}, firstLoadDelay + 150);
	}
}

// ** Load Google font(s) async ** //
function loadFonts() {
	WebFontConfig = {
		google: {
			families: [
				'Ubuntu:300,400,500,700',
				'Fira+Sans:500,900',
				'Material+Icons'
			]
		}
	};

	var wf = document.createElement('script');
	wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
	wf.type = 'text/javascript';
	wf.async = 'true';
	var s = document.getElementsByTagName('script')[0];
	s.parentNode.insertBefore(wf, s);
}

function loadGoogleMap() {
    var eGmap_ele = document.querySelector('.Map');
    if (eGmap_ele && !hasGMap) {
        var eScriptEle = document.createElement('script');
        eScriptEle.src = 'https://maps.googleapis.com/maps/api/js?key=' + eGmap_ele.getAttribute('data-api') + '&language=' + eGmap_ele.getAttribute('data-language') + '&callback=initMap';
        eScriptEle.type = 'text/javascript';
        eScriptEle.async = 'true';
        eScriptEle.setAttribute("id", "googleMap");
        var eScripts = document.getElementsByTagName('script')[0];
        eScripts.parentNode.insertBefore(eScriptEle, eScripts);
        hasGMap++;
    } else {
        initMap();
    }
}

// ** Load script async ** //
function loadScript() {
	// if you need to init some variable(s) before, they go here
	// var API_nice||{};

	var s1 = document.createElement('script'), s0 = document.getElementsByTagName('script')[0];
	s1.async = true;
	s1.src = 'yourScriptFileGoesHere.js';
	s1.charset = 'UTF-8';
	s1.setAttribute('crossorigin', '*');
	s0.parentNode.insertBefore(s1, s0);
}

// ** Smooth scrollbar ** //
function initScrollbar() {
	elContainer = document.getElementById('js-scroll');
	Scrollbar = window.Scrollbar;
	Scrollbar.init(elContainer);
	ScrollbarState = true;

	sidebar = document.getElementsByClassName('Sidebar')[0];
	Scrollbar.init(sidebar);
}

function getScrollInfo(info) {
	return Scrollbar.getAll()[0].info;
}

function getCurrentScrollTop() {
	return Scrollbar.getAll()[0].scrollTop;
}

function currentlyVisible(el) {
	return Scrollbar.getAll()[0].isVisible(el);
}

function getCurrentOffset(axis) {
	if (axis == 'y') {
		return Scrollbar.getAll()[0].offset.y;
	}

	return Scrollbar.getAll()[0].offset.x;
}

// ** Helpers (on va devoir éventuellement faire un .js séparé) ** //
function getCurrentNamespace() {
	return theContainer.dataset.namespace;
}

function setTheCurrentUrl() {
	curUrl = Barba.Utils.getCurrentUrl();
}

function getTheCurrentUrl() {
	setTheCurrentUrl();
	return curUrl;
}

function setTheCurrentLang() {
	if (theContainer.length > 0) {
		lang = theContainer.dataset.lang;

		CurLang = lang;
	}
}

function getTheCurrentLang() {
	setTheCurrentLang();
	return CurLang;
}

function tr(french, english) {
	_fr = arguments[0];
	_en = arguments[1];

	if (getTheCurrentLang() == 'en')
		return _en;

	return _fr;
}






//	function theme

function inputCheck() {
	var inputs = document.querySelectorAll('.FormElement-labeled input, .FormElement textarea');

	setTimeout(function(){
		for (var i = 0; i < inputs.length; i++) {
			const input = inputs[i];
			if(!input.value == '') {
					var parent = input.parentNode;
					parent.classList.add('is-up');
				}
		}
	}, 1000)

}

function initInputLabels() {
	var inputs = document.querySelectorAll('.FormElement-labeled input, .FormElement textarea');

	for (var i = 0; i < inputs.length; i++) {
		var input = inputs[i];
		input.addEventListener('focusin', function(e){
			var target = e.target;
			var parent = target.parentNode;
			parent.classList.add('is-up');
		});

		input.addEventListener('focusout', function(e){
			var target = e.target;

			if(target.value == '') {
				var parent = target.parentNode;
				parent.classList.remove('is-up');
			}
		});

	}
}

function initTextarea() {
	document.addEventListener('input', function (event) {
		if (!event.target.classList.contains('js-heightAdjust')) return;
		autoExpand(event.target);
	}, false);

	var textareas = document.getElementsByClassName('js-heightAdjust');
	if(textareas) {
		for (var i = 0; i < textareas.length; i++) {
			const el = textareas[i];
			autoExpand(el);
		}
	}
}

var autoExpand = function (field) {

	// Reset field height
	field.style.height = 'auto';

	// Get the computed styles for the element
	var computed = window.getComputedStyle(field);

	// Calculate the height
	var height = field.scrollHeight
				 - parseInt(computed.getPropertyValue('border-top-width'), 10)
	             - parseInt(computed.getPropertyValue('padding-top'), 10)
	             - parseInt(computed.getPropertyValue('padding-bottom'), 10)
	             - parseInt(computed.getPropertyValue('border-bottom-width'), 10);

	field.style.height = height + 'px';
	field.parentNode.style.height = height + 25 + 'px';

};



function initUploads() {

	var uploads = document.querySelectorAll('input[type="file"]');

	for (var i = 0; i < uploads.length; i++) {
		var upload = uploads[i];
		upload.addEventListener('change', function(e){
			var filenames = [];

			for(var j= 0; j < e.target.files.length; j++) {
				filenames.push(e.target.files[j].name);
			}

			e.target.nextElementSibling.textContent = filenames.join(',');
		});
	}
}


function toggleNav(action) {
	var mainNav = document.getElementsByClassName('Sidebar')[0];
	var mainNav_toggler = document.getElementsByClassName('js-toggleMenu')[0];

	switch (action) {
		case 'close':
			mainNav.classList.remove('is-active');
			mainNav_toggler.classList.remove('is-active');
			break;

		case 'open':
			mainNav.classList.add('is-active');
			mainNav_toggler.classList.add('is-active');
			break;

		default:
			if(mainNav.classList.contains('is-active')) {
				mainNav.classList.remove('is-active');
				mainNav_toggler.classList.remove('is-active');
			} else {
				mainNav_toggler.classList.add('is-active');
				mainNav.classList.add('is-active');
			}
	}
}

function toggleNavEvent() {
	var mainNavToggler = document.getElementsByClassName('js-toggleMenu')[0];

	mainNavToggler.addEventListener('click', function(){
		toggleNav();
	}) 
}

function initLightBoxes(){
	var lightBoxes = document.getElementsByClassName('js-lightbox');

	for (var i = 0; i < lightBoxes.length; i++) {
		var lightbox = lightBoxes[i];

		lightGallery(lightbox, {
			thumbnail:true,
			selector: '.js-lightboxEl'
		});
		
	}
}




// ---------------------------
// BEGIN - Anchors

	function initAnchors() {
		var links = document.querySelectorAll('[href^="#"]');

		for (var i = links.length - 1; i >= 0; i--) {
			links[i].addEventListener('click', function(e){
				e.preventDefault;
				var target = e.target.hash.substr(1);
				Scrollbar.getAll()[0].scrollIntoView(document.getElementById(target), {
					offsetTop: number = 
					150
				});
				
			});
		}
	}

// END - Anchors
// ---------------------------


function embedVideos() {
	var embeds = document.querySelectorAll('.Editable iframe[src*="youtube"], .Editable iframe[src*="vimeo"]');

	for (var i = 0; i < embeds.length; i++) {
		var embed = embeds[i];
		embed.style.height = embed.getBoundingClientRect().width * 0.5625 + 'px';
		var wrapper = document.createElement('div');
		wrapper.classList.add('EmbedWrapper');
		embed.parentNode.insertBefore(wrapper, embed);
		wrapper.appendChild(embed);
		
		
	}
}






// **---------------------------------------------------**
// -------------------------------------------------------
// DOMContentLoaded BEGIN
document.addEventListener("DOMContentLoaded", function(e) {
	// *******************************
	// Functions that get called once on document ready
	loadFonts();

	loadGoogleMap();

	toggleNavEvent();

	// scrollbar
	initScrollbar();
	Scrollbar.getAll()[0].addListener(scrolling);
	
	// watchscroll
	ScrollItem.prototype.init();

	Accordion.prototype.init();

	initInputLabels();
	inputCheck();
	initTextarea();
	initUploads();

	initLightBoxes();

	initAnchors();

	initForms();
	

	// page first laod animation
	initFirstPageLoad();

	embedVideos();

});
// DOMContentLoaded END
// -------------------------------------------------------
// **---------------------------------------------------**


// **---------------------------------------------------**
// -------------------------------------------------------
// on resize BEGIN
window.addEventListener('resize', function(){
	screenWidth = window.innerWidth;
	screenHeight = window.innerHeight;

	Accordion.prototype.update();

	embedVideos();
}, true);
// on resize END
// -------------------------------------------------------
// **---------------------------------------------------**


// **---------------------------------------------------**
// -------------------------------------------------------
// on scroll BEGIN
window.addEventListener('scroll', function(e) {
	scrolled_y = window.pageYOffset;

	if (!ticking) {
		window.requestAnimationFrame(function() {
			// place your function(s) here

			ticking = false;
		});

		ticking = true;
	}
});

/* if you have a custom scrollbar, you need to use this function to get scroll event */
function scrolling() {
	if (ScrollbarState) {
		scrolled_y = getCurrentScrollTop();
	}

	if (!ticking) {
		window.requestAnimationFrame(function() {
			// place your function(s) here
			ScrollItem.prototype.watch();
			ticking = false;
		});

		ticking = true;
	}
}
// on scroll BEGIN
// **---------------------------------------------------**
// -------------------------------------------------------